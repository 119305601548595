// See https://github.com/palantir/blueprint/issues/959
let moment = require("moment");
if ("default" in moment) {
    moment = moment["default"];
}

export function toDate(dateString) {
    return moment(dateString, "YYYY-MM-DD");
}

export function toRemigDate(dateString) {
    return moment(dateString, "YYYY-MM-DD").format("D.M.YYYY");
}

export function toDateWithoutYear(dateString) {
    return moment(dateString, "YYYY-MM-DD").format("D.M");
}

export function toIsoDate(dateString) {
    return moment(dateString, "D.M.YYYY").format("YYYY-MM-DD");
}

export function minusMonths(dateString, months) {
    const date = moment(dateString, "YYYY-MM-DD");
    return date.subtract(months, 'months');
}

export function minusMonthsExclusive(dateString, months) {
    var date = moment(dateString, "YYYY-MM-DD");
    date = date.subtract(months, 'months');
    return date.subtract(1, 'days');
}

export function minusDays(dateString, days) {
    const date = moment(dateString, "YYYY-MM-DD");
    return date.subtract(days, 'days');
}

export function minusWeeks(dateString, weeks) {
    const date = moment(dateString, "YYYY-MM-DD");
    return date.subtract(weeks, 'weeks');
}

export function plusWeeks(dateString, weeks) {
    const date = moment(dateString, "YYYY-MM-DD");
    return date.add(weeks, 'weeks');
}

export function formatDate(date) {
    return date.format("D.M.YYYY");
}
