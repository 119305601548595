export function registerCsrf() {
    const csrfToken = $("meta[name='_csrf_token']").attr("content");
    const csrfParameter = $("meta[name='_csrf_parameter']").attr("content");
    // const csrfHeader = $("meta[name='_csrf_header']").attr("content");

    $(document).on('click', 'a.csrf-protected', (e: JQueryEventObject) => {
        e.preventDefault();
        let $a = $(e.target);

        let $form = $('<form style="display:none" method="post"></form>').attr('action', $a.attr('href'));
        $form.append($('<input type="hidden">').attr("name", csrfParameter).val(csrfToken));
        $form.append($('<input type="submit">'));
        $('body').append($form);

        $form.submit();
    });
}
